<template>
  <div class="order">
    <div class="handle-box">
      <span>日期</span>
      <el-date-picker v-model="value2"
                      style="width: 240px"
                      :clearable="false"
                      :default-value="defaultDate"
                      @change="changeTimer"
                      format="YYYY-MM-DD"
                      value-format="YYYY-MM-DD"
                      type="daterange"
                      align="right"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      :shortcuts="shortcuts">
        <!-- unlink-panels -->
      </el-date-picker>

      <span>订单号:</span>
      <el-input v-model.trim="orderId"
                placeholder="请输入标题或ID"
                class="handle-input mr10 w100"></el-input>
      <span> 订单类型:</span>
      <el-select v-model="orderTypeId"
                 placeholder="请选择"
                 class="handle-select mr10 w80">
        <el-option key=""
                   label="全部"
                   value=""></el-option>
        <el-option v-for="(item,i) in days "
                   :key="i"
                   :label="item.remark"
                   :value="item.orderTypeId"></el-option>
      </el-select>
      <span>订单状态:</span>
      <el-select v-model="status"
                 @change="fatherChange"
                 placeholder="请选择"
                 class="handle-select mr10 ">
        <el-option key=""
                   label="全部"
                   value=""></el-option>
        <el-option :key="item.id"
                   v-for="(item) in orderStatus "
                   :label="item.name"
                   :value="item.id"></el-option>

      </el-select>
      <span>支付类型:</span>
      <el-select v-model="payType"
                 placeholder="请选择"
                 class="handle-select mr10 ">
        <el-option :key="item.id"
                   v-for="(item) in payTypeList "
                   :label="item.name"
                   :value="item.id"></el-option>
      </el-select>

    </div>
    <div class="handle-box">
      <span>交易订单号:</span>
      <el-input v-model.trim="tradeNo"
                placeholder="请输入交易订单号"
                class="handle-input mr10 w100"></el-input>
      <span>用户ID:</span>
      <el-input v-model.trim="userId"
                placeholder="请输入用户ID"
                class="handle-input mr10 w100"></el-input>
      <span>昵称:</span>
      <el-input v-model.trim="nickName"
                placeholder="请输入昵称"
                class="handle-input mr10 w100"></el-input>
      <span>客户端类型:</span>
      <el-input v-model.trim="clientId"
                placeholder="请输入客户端类型"
                class="handle-input mr10 w100"></el-input>

      <el-button type="primary ml10"
                 style="marginLeft: 10px"
                 icon="el-icon-search"
                 @click="searchData">搜索</el-button>
      <el-button type="primary ml10"
                 icon="el-icon-delete"
                 @click="clearData">清空</el-button>
      <el-button type="primary ml10"
                 icon="el-icon-message"
                 @click="downFile">导出</el-button>
      <el-button type="primary ml10"
                 icon="el-icon-check"
                 @click="resetList">批量退款</el-button>

    </div>
    <div class="table">
      <el-table ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                @selection-change="handleSelectionChange">
        <el-table-column type="selection"
                         width="55">
        </el-table-column>
        <el-table-column label="订单号"
                         align="center"
                         width="170">
          <template #default="scope">{{ scope.row.orderId }}</template>
        </el-table-column>
        <el-table-column prop="orderType"
                         align="center"
                         label="订单类型"
                         width="120">
        </el-table-column>
        <el-table-column prop="tradeNo"
                         align="center"
                         width="140"
                         label="交易订单号">
        </el-table-column>
        <el-table-column prop="productId"
                         align="center"
                         label="套餐ID">
        </el-table-column>
        <el-table-column prop="productName"
                         align="center"
                         label="套餐名称">
        </el-table-column>
        <el-table-column prop="productRemark"
                         align="center"
                         label="套餐内容">
        </el-table-column>
        <el-table-column prop="createTime"
                         align="center"
                         label="订单创建时间">
          <template #default="scope">
            <span> {{ scope.row.createTime && scope.row.createTime.replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '').slice( 0,19)  }}</span>
          </template>

        </el-table-column>
        <el-table-column prop="userId"
                         align="center"
                         label="用户ID">
        </el-table-column>
        <el-table-column prop="nickName"
                         align="center"
                         label="用户昵称">
        </el-table-column>
        <el-table-column prop="amount"
                         align="center"
                         label="订单价格">
        </el-table-column>
        <el-table-column prop="payType"
                         align="center"
                         label="支付方式">
          <template #default="scope">
            <span> {{ scope.row.payType==1 ? "微信" : "支付宝" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="payTime"
                         align="center"
                         width="180"
                         label="支付时间">
          <template #default="scope">
            <span> {{ scope.row.payTime && scope.row.payTime.replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '').slice( 0,19)  }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="id"
                         label="支付状态">
        </el-table-column> -->
        <el-table-column prop="status"
                         align="center"
                         label="订单状态">
          <template #default="scope">
            <span v-if="scope.row.status==0">未付款</span>
            <span v-if="scope.row.status==1">已付款</span>
            <span v-if="scope.row.status==2">已退款</span>
            <span v-if="scope.row.status==3">过期作废</span>
          </template>

        </el-table-column>
        <el-table-column prop="channel"
                         align="center"
                         label="渠道">
        </el-table-column>
        <el-table-column prop="clientId"
                         align="center"
                         label="客户端类型">
        </el-table-column>
        <el-table-column prop="id"
                         align="center"
                         label="操作">
          <template #default="scope">
            <el-button type="primary"
                       v-if="scope.row.status == 1">退款</el-button>
            <span v-if="scope.row.status==2">已退款</span>
            <span v-if="scope.row.status==3">过期作废</span>
          </template>

        </el-table-column>

      </el-table>
      <div class="page"
           v-if="total>20">
        <el-pagination background
                       layout=" prev, pager, next"
                       :current-page="pageIndex"
                       :page-size="20"
                       :total="total"
                       @current-change="pageChange"></el-pagination>
      </div>

    </div>

  </div>
</template>
<script>
// getDownExcel
import { getOrderList, getDayTimer } from "../../api/index";
import axios from "axios"
export default {
  data () {
    return {
      name: "order",
      documentType: "",
      isEnable: "",
      categoryId: "",
      fatherList: [{ id: 1, name: "" }],
      SonId: "",
      sonList: [{ id: 1, name: "" }],
      value2: "",
      defaultDate: [],
      shortcuts: [{
        text: '最近一周',
        value: (() => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
          return [start, end]
        })(),
      }, {
        text: '最近一个月',
        value: (() => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
          return [start, end]
        })(),
      }, {
        text: '最近三个月',
        value: (() => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
          return [start, end]
        })(),
      }],
      days: [],
      tableData: [],
      multipleSelection: [],
      orderStatus: [
        {
          name: "未付款",
          id: 0,
        },
        {
          name: "已付款",
          id: 1,
        }, {
          name: "已退款",
          id: 2,
        }, {
          name: "过期作废",
          id: 3,
        },
      ],
      payTypeList: [
        { name: "全部", id: "" },
        { name: "微信", id: 1 },
        { name: "支付宝", id: 2 },
      ],
      total: 0,
      pageIndex: 1,
      startTime: "",
      endTime: "",
      orderId: "",
      status: "",
      payType: "",
      tradeNo: "",
      userId: "",
      nickName: "",
      clientId: "",
      orderTypeId: "", //订单类型
    }
  },
  mounted () {
    this.getData();
    this.getDays();
  },
  methods: {
    // 导出功能
    downFile () {
      let params = {
        startTime: this.startTime,
        endTime: this.endTime,
        orderId: this.orderId,
        status: this.status,
        payType: this.payType,
        tradeNo: this.tradeNo,
        userId: this.userId,
        nickName: this.nickName,
        clientId: this.clientId,
        pageIndex: this.pageIndex,//必需
        pageSize: 20,//必需
        orderTypeId: this.orderTypeId, //订单类型
      };
      let data = {};
      let url = process.env.VUE_APP_BASE_MIAOHUI + "/miaohui/admin/order/export";
      // excel 表格下载
      axios({
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; application/octet-stream'
        },
        url: url,
        params: params,
        data: data,
        responseType: 'arraybuffer'
      })
        .then(res => {
          let b = new Blob([res.data], { type: 'application/vnd.ms-excel' });
          let url = URL.createObjectURL(b);
          let link = document.createElement('a');
          link.download = "订单数据" + new Date(+new Date() + 8 * 3600 * 1000).toJSON().substr(0, 19).replace("T", " ") + ".xls";
          link.href = url;
          link.click();
        })
        .catch(err => {
          console.log(err);
        });
    },
    // 获取时间
    getDays () {
      getDayTimer().then(res => {
        if (res.code == 0) {
          this.days = res.data;
        }
      })
    },

    changeTimer (v) {
      //  格式化时间
      const d1 = new Date(v[0]);
      const d2 = new Date(v[1]);
      const resDate = d1.getFullYear() + '-' + this.p((d1.getMonth() + 1)) + '-' + this.p(d1.getDate());
      const eDate = d2.getFullYear() + '-' + this.p((d2.getMonth() + 1)) + '-' + this.p(d2.getDate());
      this.startTime = resDate;
      this.endTime = eDate;
      console.log(resDate, eDate);
    },
    p (s) {
      return s < 10 ? '0' + s : s
    },

    searchData () {
      this.pageIndex = 1;
      this.getData();
    },
    clearData () {
      this.value2 = "";
      this.pageIndex = 1;
      this.startTime = "";
      this.endTime = "";
      this.orderId = "";
      this.status = "";
      this.payType = "";
      this.tradeNo = "";
      this.userId = "";
      this.nickName = "";
      this.clientId = "";
      this.orderTypeId = ""; //订单类型	
      this.getData();
    },
    getData () {
      let params = {
        startTime: this.startTime,
        endTime: this.endTime,
        orderId: this.orderId,
        status: this.status,
        payType: this.payType,
        tradeNo: this.tradeNo,
        userId: this.userId,
        nickName: this.nickName,
        clientId: this.clientId,
        pageIndex: this.pageIndex,//必需
        pageSize: 20,//必需
        orderTypeId: this.orderTypeId, //订单类型
      };
      getOrderList(params).then(res => {
        console.log(res);
        this.tableData = res.data.data;
        this.total = res.data.total;// 总数 
        console.log(this.tableData, "格式");
      })
    },
    pageChange (v) {
      this.pageIndex = v;
      this.getData();
      console.log("当前页", v);
    },
    changeLabel () { },
    fatherChange () { },
    resetList () { },
    toggleSelection (rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange (val) {
      this.multipleSelection = val;
    },


  }
}
</script>
<style lang="scss">
.order {
  .handle-box {
    padding-top: 10px;
    .w100 {
      width: 180px;
    }
  }
  .table {
    padding-top: 20px;
  }
  .page {
    .el-pagination {
      text-align: center;
      padding-top: 20px;
    }
  }
}
</style>